<template>
  <v-dialog width="50%" v-model="dialog" persistent>
    <v-card>
      <v-toolbar dark color="blue lighten-1" dense>
        <v-toolbar-title>Passportni taxrirlash</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon color="error"> mdi-close </v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-text-field
          label="Raqam"
          v-model="numberTextField.vModel"
          maxlength="4"
        />
        <v-text-field
          label="Passport"
          v-model="passportTextField.vModel"
          maxlength="9"
        />
        <v-text-field
          label="Ism Famiyla Otasini ismi"
          v-model="fullNameTextField.vModel"
        />
      </v-container>
      <v-divider class="blue lighten-1"></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn small color="warning" @click="dialog = false" class="w-40"
          >Bekor qilish</v-btn
        >
        <v-btn small color="success" @click="updatePassport" class="w-40"
          >Saqlash</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "../../axios";

export default {
  name: "AddPassportDialog",
  props: {
    afterSave: {
      type: Function,
      default: async () => {},
    },
  },
  data: () => ({
    dialog: false,
    resource: {
      passportId: null,
    },
    numberTextField: {
      vModel: "",
    },
    passportTextField: {
      vModel: "",
    },
    fullNameTextField: {
      vModel: "",
    },
  }),
  methods: {
    init(passport) {
      this.resource.passportId = passport.id;
      this.numberTextField.vModel = passport.number;
      this.passportTextField.vModel = passport.passport;
      this.fullNameTextField.vModel = passport.full_name;
      this.dialog = true;
    },
    async updatePassport() {
      if (!this.numberTextField.vModel.toString().match("^[1-9]+$"))
        return this.$toast.error("Raqamni kiritishda xatolik !");
      if (!this.passportTextField.vModel.match("^[A-Z][A-Z][1-9]{7}$"))
        return this.$toast.error("Passport ma'lumotini kiritishda xatolik !");
      if (!this.fullNameTextField.vModel)
        return this.$toast.error(
          "Ism Familya Otasini ismi kiritishda xatolik !"
        );
      try {
        this.$overlay(true);

        const { data } = await axios.put(
          `/passports/${this.resource.passportId}`,
          {
            id: this.resource.passportId,
            number: this.numberTextField.vModel,
            passport: this.passportTextField.vModel,
            full_name: this.fullNameTextField.vModel,
          }
        );

        this.dialog = false;
        this.$toast.success(data.message || "");
        await this.afterSave();
      } catch (error) {
        this.$toast.error(error.response?.data?.message || error.message || "");
      } finally {
        this.$overlay(false);
      }
    },
  },
};
</script>
