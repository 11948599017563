<template>
  <v-container fluid>
    <v-row dense>
      <section-title>Фойдаланувчи маълумотлари</section-title>
      <v-spacer />
      <v-btn small dark class="blue darken-1" @click="openAddPassportDialog">
        <v-icon small class="mr-2">mdi-plus-box</v-icon>
        Qo'shish
      </v-btn>
    </v-row>

    <v-divider class="light-blue lighten-8 mt-2 mb-2 elevation-10" />

    <v-row dense>
      <v-col cols="12" lg="3" md="3" sm="12">
        <v-text-field
          label="RAQAMI"
          v-model="numberTextField.vModel"
          append-icon="mdi-magnify"
          clearable
          @keyup.enter="fetchPassports"
          hide-details
          @click:clear="clearNumberTextField"
          @keydown.esc="clearNumberTextField"
        />
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="12">
        <v-text-field
          label="FIO"
          v-model="fullNameTextField.vModel"
          append-icon="mdi-magnify"
          clearable
          @keyup.enter="fetchPassports"
          hide-details
          @click:clear="clearFullNameTextField"
          @keydown.esc="clearFullNameTextField"
        />
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="12">
        <v-text-field
          label="PASSPORT"
          v-model="passportTextField.vModel"
          append-icon="mdi-magnify"
          clearable
          @keyup.enter="fetchPassports"
          hide-details
          @click:clear="clearPassportTextField"
          @keydown.esc="clearPassportTextField"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          fixed-header
          height="70vh"
          :headers="passportDataTable.headers"
          :items="passportDataTable.data"
          no-data-text="Ma'lumotlar mavjud emas !"
          disable-filtering
          disable-sort
          :footer-props="{
            itemsPerPageOptions: [10, 15, 20, 30, 40, 50],
            itemsPerPageText: 'Jadvaldagi qatorlar soni: ',
          }"
          :server-items-length="passportDataTable.meta.total"
          :options.sync="passportDataTable.options"
          dense
        >
          <template #item.passport="{ item }">
            {{ item.passport.replace(/([A-Z]{2})([1-9|0]{7})/, "$1 $2") }}
          </template>
          <template #item.actions="{ item: passport }">
            <v-btn
              icon
              @click="openEditPassportDialog(passport)"
              color="warning"
            >
              <v-icon>mdi-pencil-box</v-icon>
            </v-btn>
            <v-btn icon @click="deletePassport(passport)" color="error">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <add-passport-dialog ref="AddPassportDialog" :after-save="fetchPassports" />
    <edit-passport-dialog
      ref="EditPassportDialog"
      :after-save="fetchPassports"
    />
  </v-container>
</template>

<script>
import axios from "../../axios";
import SectionTitle from "../../components/SectionTitle.vue";
import AddPassportDialog from "./add-passport-dialog.vue";
import EditPassportDialog from "./edit-passport-dialog.vue";

export default {
  name: "Passport",
  components: { AddPassportDialog, EditPassportDialog, SectionTitle },
  data: () => ({
    numberTextField: {
      vModel: null,
    },
    passportTextField: {
      vModel: null,
    },
    fullNameTextField: {
      vModel: null,
    },
    passportDataTable: {
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        {
          text: "RAQAM",
          value: "number",
          divider: true,
          class: "blue lighten-1 white--text",
        },
        {
          text: "FIO",
          value: "full_name",
          divider: true,
          class: "blue lighten-1 white--text",
        },
        {
          text: "PASSPORT",
          value: "passport",
          divider: true,
          class: "blue lighten-1 white--text",
        },
        {
          text: "AMALLAR",
          value: "actions",
          divider: false,
          class: "blue lighten-1 white--text",
        },
      ],
      data: [],
      meta: {
        total: 1,
      },
    },
  }),
  watch: {
    "passportDataTable.options": "fetchPassports",
  },
  created() {
    this.fetchPassports();
  },
  methods: {
    async fetchPassports() {
      try {
        this.$overlay(true);

        const { data: passports } = await axios.get("/passports", {
          params: {
            full_name: this.fullNameTextField.vModel,
            number: this.numberTextField.vModel,
            passport: this.passportTextField.vModel,
            page: this.passportDataTable.options?.page,
            per_page: this.passportDataTable.options?.itemsPerPage,
          },
        });
        this.passportDataTable.data = passports.data;
        this.passportDataTable.meta.total = passports.total;
      } catch (error) {
        this.$toast.error(error?.response?.data?.message || error.message);
      } finally {
        this.$overlay(false);
      }
    },
    async clearNumberTextField() {
      this.numberTextField.vModel = null;
      await this.fetchPassports();
    },
    async clearPassportTextField() {
      this.passportTextField.vModel = null;
      await this.fetchPassports();
    },
    async clearFullNameTextField() {
      this.fullNameTextField.vModel = null;
      await this.fetchPassports();
    },
    openAddPassportDialog() {
      this.$refs.AddPassportDialog.init();
    },
    openEditPassportDialog(passport) {
      this.$refs.EditPassportDialog.init(passport);
    },
    async deletePassport(passport) {
      if (!confirm("Passportni o'chirishga rozimisiz ?")) return;

      try {
        this.$overlay(true);

        const { data } = await axios.delete(`/passports/${passport.id}`);

        await this.fetchPassports();
        this.$toast.success(data.message);
      } catch (error) {
        this.$toast.error(error?.response?.data?.message || error.message);
      } finally {
        this.$overlay(false);
      }
    },
  },
};
</script>
